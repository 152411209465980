import { z } from 'zod'
import { stringTrimTransformFunction } from '../lib'
import { VALIDATION_MESSAGES } from '../../constants'

/*
The regular expression is looking for:
  Optionally, whitespace characters at the beginning (^\s).

  US ZIP codes can be in the formats: 12345 or 12345-6789.
  Exactly five digits (\d{5}).
  Optionally, a hyphen or whitespace followed by four digits ((?:[-\s]\d{4})?).
  Optionally, whitespace characters in the beginning or in the end (\s$).

  Examples of valid ZIP codes:
  12345
  12345-6789
  12345 6789

  Examples of invalid ZIP codes:
  1234
  123456
  12345-678
  12345-67890


  CA postal codes follow the format: A1A 1A1.
  Any number of whitespace characters in the middle is allowed

  Optionally, whitespace characters in the beginning or in the end (\s$).


  China Postal Code Format
  Explanation:
    ^: Asserts the beginning of the string.
    [1-9]: The first digit must be between 1 and 9 (non-zero).
    \d{4,5}: Matches 4 to 5 additional digits (ensures the total length is 5 or 6 digits).
    $: Asserts the end of the string.

    Examples of Valid Matches:
    10000 (5 digits)
    100000 (6 digits)
    12345
    987654

    Examples of Invalid Matches:
    01234 (Starts with 0)
    1234 (Too short)
    1234567 (Too long)
    12345A (Contains a non-numeric character)
*/
export const US_ZIP_CODE_REGEX = /^\s*\d{5}(?:[-\s]\d{4})?\s*$/
export const CA_ZIP_CODE_REGEX = /^\s*[A-Za-z]\d[A-Za-z]\s*\d[A-Za-z]\d\s*$/
export const CHINA_ZIP_CODE_REGEX = /^[1-9]\d{4,5}$/
export const CA_ZIP_CODE_SHORTEN_REGEX = /^([A-Z]\d){3}/
export const PostalCodeFieldSchema = z
  .string()
  .refine(
    value => {
      return US_ZIP_CODE_REGEX.test(value) || CA_ZIP_CODE_REGEX.test(value)
    },
    {
      message: VALIDATION_MESSAGES.INVALID_ZIP_CODE,
    },
  )
  .transform(stringTrimTransformFunction)
