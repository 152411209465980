import React, { PropsWithChildren } from 'react'
import { useConnectHubspotScript } from 'lib'
import { Header } from './Header'
import { Footer } from './Footer'

export const PageLayout = ({ children }: PropsWithChildren) => {
  useConnectHubspotScript()

  // useEffect(() => {
  //   Calendly.setup()
  // }, [])

  return (
    <>
      <Header />
      <main className="pb-20">{children}</main>
      <Footer />
    </>
  )
}
