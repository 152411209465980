import { LocalStorageManager } from '../model'

// NOTE: seller-portal
export const lastOnboardingStep = new LocalStorageManager<number>('lastOnboardingStep')
export const lastOnboardingMarketplaceType = new LocalStorageManager<string>(
  'lastOnboardingMarketplaceType',
)
export const continueOnboardingBanner = new LocalStorageManager<{
  hidden: boolean
  collapsed: boolean
}>('continueOnboardingBanner')
export const lastOnboardingMarketplaceId = new LocalStorageManager<string>(
  'lastOnboardingMarketplaceId',
)
export const isOnboardingFinished = new LocalStorageManager<boolean>('isOnboardingFinished')
export const shopifyInstallationCreds = new LocalStorageManager<{
  accessToken: string
  domain: string
}>('shopifyInstallationCreds')
export const walmartInstallationCreds = new LocalStorageManager<{
  sellerId: string
  code: string
}>('walmartInstallationCreds')
export const isOnboardingBannerVisible = new LocalStorageManager<boolean>(
  'isOnboardingBannerVisible',
)
export const onboardingCompletedSteps = new LocalStorageManager<number[]>(
  'onboardingCompletedSteps',
)

// NOTE: shopper-portal
export const accessTokenLocalStorageManager = new LocalStorageManager<string>('accessToken')
export const refreshTokenLocalStorageManager = new LocalStorageManager<string>('refreshToken')
export const currentUserLocalStorageManager = new LocalStorageManager<{
  id: string
  email: string
}>('currentUser')
export const currentOrderIdLocalStorageManager = new LocalStorageManager<string>('currentOrderId')
export const currentOrganizationIdLocalStorageManager = new LocalStorageManager<string>(
  'currentOrganizationId',
)
export const currentEmailLocalStorageManager = new LocalStorageManager<string>('currentEmail')
export const currentInternalOrderIdLocalStorageManager = new LocalStorageManager<number>(
  'internalOrderId',
)
export const currentClaimIdLocalStorageManager = new LocalStorageManager<string>('claimId')
export const tablesOrderedValueColumIdsStorageManager = new LocalStorageManager<{
  [tableId: string]: string[]
}>('tablesOrderedValueColumIds')

// NOTE: hubspot
export const hubSpotContactId = new LocalStorageManager<string>('hubSpotContactId')
export const hubSpotDealId = new LocalStorageManager<string>('hubSpotDealId')
export const hubSpotContactEmail = new LocalStorageManager<string>('hubSpotContactEmail')

// NOTE: walmart-connect
export const walmartConnectStateIdLocalStorageManager = new LocalStorageManager<string>(
  'walmartConnectStateId',
)
export const walmartConnectUpdateOrganizationIdLocalStorageManager =
  new LocalStorageManager<string>('walmartConnectUpdateOrganizationId')
