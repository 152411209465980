import * as React from 'react'
import { Flex, Link } from '@r1/ui-kit'
import styled from '@emotion/styled'
import { PRIVACY_POLICY_URI, USER_AGREEMENT_URI, PRICING_URI } from '../../constants/privacy'

export const SubmitButtonContainer = styled(Flex)`
  width: 100%;
  > button {
    width: 100%;
  }
`

export const AgreementContainer = styled(Flex)`
  font-size: 12px;
`
export const Privacy = ({
  verbose,
  isShowPricing,
}: {
  verbose?: boolean
  isShowPricing?: boolean
}) => {
  return (
    <AgreementContainer mt={verbose ? 'L' : 'XXXXL'} mb={verbose ? 'XXS' : 'XL'} justify="center">
      {verbose && <span>By proceeding, you agree to our</span>}
      <Flex ml="XXS" mr="XXS">
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Link style={verbose ? {} : { color: '#626F7D' }} href={USER_AGREEMENT_URI}>
          User Agreement
        </Link>
      </Flex>
      {verbose ? 'and' : '·'}
      <Flex ml="XXS" mr="XXS">
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Link style={verbose ? {} : { color: '#626F7D' }} href={PRIVACY_POLICY_URI}>
          Privacy Policy
        </Link>
      </Flex>
      {isShowPricing && (
        <>
          {verbose ? 'and' : '·'}
          <Flex ml="XXS" mr="XXS">
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <Link style={verbose ? {} : { color: '#626F7D' }} href={PRICING_URI}>
              ReturnPro pricing
            </Link>
          </Flex>
        </>
      )}
    </AgreementContainer>
  )
}
